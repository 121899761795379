import { useContext } from "react";
import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";
import Header from "../components/header";
import LanguageContext from "../contexts/languageContext";
import TextsContext from "../contexts/textsContext";

export default function Default() {
	const texts = useContext(TextsContext);
	const { language } = useContext(LanguageContext);

	return (
		<div>
			<Header />
			<Container>
				<Alert variant="danger">
					<b>{texts.error404[language]}</b>: {texts.error404Message[language]}
				</Alert>
			</Container>
		</div>
	);
}
