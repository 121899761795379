import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { PropsWithChildren } from "react";
import { useNavigate } from "react-router";
import Card from "react-bootstrap/Card";
import classNames from "classnames";
import "../styles/userCard.css";

export interface UserCardProps {
	icon: IconProp;
	name: string;
	text?: string;
	link?: string;
	disabled?: boolean;
	onClick?: () => void;
}

export default function ActionCard(props: PropsWithChildren<UserCardProps>) {
	const navigate = useNavigate();

	return (
		<Card
			className={classNames("w-100 mb-3 user-card", {
				active: !props.disabled,
			})}
			onClick={() => {
				props.onClick?.();
				if (props.link) navigate(props.link);
			}}>
			<Card.Body>
				<Card.Title className="text-center mb-4">{props.name}</Card.Title>
				<FontAwesomeIcon className="w-100 mb-4" icon={props.icon} size="10x" />
				<Card.Text className="text-center">{props.text}</Card.Text>
			</Card.Body>
		</Card>
	);
}
