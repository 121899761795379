import { useClientTrigger, usePresenceChannel } from "@harelpls/use-pusher";
import { useContext } from "react";
import { useNavigate } from "react-router";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Header from "../components/header";
import LanguageContext from "../contexts/languageContext";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import TextsContext from "../contexts/textsContext";

interface WaitingRoomMember {
	name: string;
	role: "employee" | "patient" | "tablet";
}

export default function Employee() {
	const navigate = useNavigate();

	const texts = useContext(TextsContext);
	const { language } = useContext(LanguageContext);

	const { channel, count, members, me, myID } = usePresenceChannel("presence-waiting-room");
	const clientTrigger = useClientTrigger<{ key: string }>(channel);

	return (
		<div>
			<Header />
			<Container>
				<>
					<span> {texts.loggedInAs[language](me?.info.name ?? "")} </span>
					{members && (count ?? 0) > 1 ? (
						<Row className="mt-2">
							{Object.entries(members as Record<string, WaitingRoomMember>).map(
								([key, member]) =>
									member.role !== "employee" &&
									key !== myID && (
										<Col key={key} xs={12} md={6}>
											<Button
												className="w-100 mb-3"
												variant="outline-success"
												onClick={() => {
													clientTrigger("client-start", { key: key });
													navigate(`/processes/employee/${key}`);
												}}>
												{member.name}
											</Button>
										</Col>
									),
							)}
						</Row>
					) : (
						<Alert className="w-100 mt-2" variant="success">
							<Spinner size="sm" animation="border" variant="success" />
							<span> {texts.waitingForPatientsMessage[language]} </span>
						</Alert>
					)}
				</>
			</Container>
		</div>
	);
}
