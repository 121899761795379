import {
	AdmissionProcess,
	AdmissionRule,
	AdmissionStep,
	BaseFormField,
	DrawableField,
	FormField,
	FormFieldValue,
} from "../types/admissionTypes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { convertProcess } from "../api/processConverter";
import {
	faCheck,
	faEraser,
	faFileContract,
	faPencil,
	faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { prefill } from "../prefillSystem";
import { useAuth } from "oidc-react";
import { useClientTrigger, useEvent, usePresenceChannel } from "@harelpls/use-pusher";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useMediaQuery } from "usehooks-ts";
import { useNavigate, useParams } from "react-router";
import { useQueries, useQuery } from "react-query";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import CanvasDraw from "react-canvas-draw";
import Container from "react-bootstrap/Container";
import EnvContext from "../contexts/envContext";
import Form from "react-bootstrap/Form";
import FormActions from "../components/formActions";
import Header from "../components/header";
import LanguageContext from "../contexts/languageContext";
import ListGroup from "react-bootstrap/ListGroup";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import StepProgressBar from "../components/stepProgressBar";
import TextsContext from "../contexts/textsContext";
import fetchDocument, { Document } from "../api/fetchDocument";
import fetchProcess from "../api/fetchProcess";
import useSignotecPad from "../hooks/useSignotecPad";
import useSyncedState from "../hooks/useSyncedState";
import "../styles/edit.css";

library.add(faTriangleExclamation, faCheck, faPencil, faEraser, faFileContract);

const placeholderProcess: AdmissionProcess = {
	id: 0,
	name: "",
	steps: [
		{
			drawableFields: [],
			fields: [],
			name: "",
			pages: [],
			pdf: "",
			affectingRules: [],
			hidden: true,
		},
	],
};

export default function Edit() {
	const navigate = useNavigate();
	const { user, key, process: processId } = useParams();

	const pagesRef = useRef<Array<HTMLFormElement | null>>([]);

	const { tenant, env } = useContext(EnvContext);
	const texts = useContext(TextsContext);
	const { language } = useContext(LanguageContext);

	const auth = useAuth();

	const [showMessages, setShowMessages] = useState(false);

	const { channel, myID, count } = usePresenceChannel(`presence-edit-${key}`);

	const isSm = useMediaQuery("(min-width: 576px)");
	const isMd = useMediaQuery("(min-width: 768px)");
	const isLg = useMediaQuery("(min-width: 992px)");
	const isXl = useMediaQuery("(min-width: 1200px)");

	const canvasDrawRef = useRef<CanvasDraw>(null);

	const process = useQuery("process", () =>
		fetchProcess({
			env,
			tenant,
			token: auth.userData?.access_token ?? "",
			processId: Number.parseInt(processId ?? ""),
		}),
	);

	const documents = useQueries(
		process.data
			? process.data.process.steps.map((processStep) => {
					return {
						queryKey: `processDocument${processStep.document_id}`,
						queryFn: () =>
							fetchDocument({
								documentId: processStep.document_id,
								env,
								tenant,
								token: auth.userData?.access_token ?? "",
							}),
					};
			  })
			: [],
	);

	const docs = useMemo(
		() => documents.map((doc) => doc.data?.document).filter((doc): doc is Document => !!doc),
		[documents],
	);

	const admissionProcess = useMemo(() => {
		const processData = process.data;
		if (!processData) return placeholderProcess;
		if (docs.length !== processData.process.steps.length) return placeholderProcess;

		return convertProcess(
			{
				env,
				tenant,
				token: auth.userData?.access_token ?? "",
			},
			processData.process,
			docs,
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [process.data, docs]);

	const [currentStep, setCurrentStep] = useSyncedState(0, "current-step", channel);

	const admissionStep = admissionProcess.steps[currentStep];

	const [formInput, setFormInput] = useSyncedState<Record<string, FormFieldValue>>(
		{},
		"form-input",
		channel,
	);

	const [formDrawableInput, setFormDrawableInput] = useSyncedState<
		Record<string, string | undefined>
	>({}, "form-drawable-input", channel, true);

	const [focusedElements, setFocusedElements] = useSyncedState<Record<string, string | undefined>>(
		{},
		"focused-elements",
		channel,
	);

	const formFields = useMemo(
		() => admissionProcess.steps.map((step) => step.fields).flat(1),
		[admissionProcess],
	);

	const drawableFields = useMemo(
		() => admissionProcess.steps.map((step) => step.drawableFields).flat(1),
		[admissionProcess],
	);

	const otherFormFocus = Object.entries(focusedElements).find(([key]) => key !== myID)?.[1];
	const thisFormFocus = Object.entries(focusedElements).find(([key]) => key === myID)?.[1];

	const selectedDrawableField = admissionStep.drawableFields.find(
		(field) => field.identifier === thisFormFocus,
	);

	useEffect(() => {
		window.addEventListener("beforeunload", alertUser);
		return () => {
			window.removeEventListener("beforeunload", alertUser);
		};
	}, []);

	function alertUser(event: BeforeUnloadEvent) {
		event.preventDefault();
		event.returnValue = "";
	}

	function isFormFieldFilled(field: FormField) {
		const input = field.toggleGroupElement
			? formInput[field.toggleGroupElement]
			: formInput[field.identifier];

		const isFilled = input !== undefined && input !== "" && input !== false;

		const isPrefilled =
			!isFilled && field.prefill !== undefined && prefill(field.prefill) !== undefined;

		return isFilled || isPrefilled;
	}

	function isDrawableFieldFilled(field: DrawableField) {
		const isFilled = !!formDrawableInput[field.identifier];
		return isFilled;
	}

	function getActiveRules(affectingRules: AdmissionRule[]) {
		const activeRules = affectingRules.filter((rule) => {
			if (!rule.entryField) return true;

			const formField = formFields.find((field) => field.uid === rule.entryField);
			const drawableField = drawableFields.find((field) => field.uid === rule.entryField);

			const isFilled = formField
				? isFormFieldFilled(formField)
				: drawableField
				? isDrawableFieldFilled(drawableField)
				: undefined;

			if (isFilled === undefined) return false;

			if (rule.entryCondition === "empty") {
				return !isFilled;
			} else if (rule.entryCondition === "filled") {
				return isFilled;
			} else {
				return false;
			}
		});

		return activeRules;
	}

	function getFormFieldConflictingRules(field: FormField) {
		const activeRules = getActiveRules(field.affectingRules);

		const conflictingRules = activeRules.filter((rule) => {
			if (rule.action === "requirement" || rule.action === "warning") {
				return !isFormFieldFilled(field);
			} else {
				return isFormFieldFilled(field);
			}
		});

		return conflictingRules;
	}

	function getDrawableFieldConflictingRules(field: DrawableField) {
		const activeRules = getActiveRules(field.affectingRules);

		const conflictingRules = activeRules.filter((rule) => {
			if (rule.action === "requirement" || rule.action === "warning") {
				return !isDrawableFieldFilled(field);
			} else {
				return isDrawableFieldFilled(field);
			}
		});

		return conflictingRules;
	}

	function shouldStepShow(step: AdmissionStep) {
		const activeRules = getActiveRules(step.affectingRules);

		if (activeRules.some((rule) => rule.action === "exclude")) return false;

		if (!step.hidden) return true;

		return activeRules.length > 0;
	}

	function getUniqueFields<T extends BaseFormField>(fields: T[]) {
		const uniqueFields = fields.filter((field: T | FormField) =>
			"toggleGroupElement" in field ? field.toggleGroupElement === field.identifier : true,
		);

		return uniqueFields;
	}

	function getFieldConflicts() {
		const conflicts = new Map([
			...getUniqueFields(formFields)
				.map((field) => [field, getFormFieldConflictingRules(field)] as const)
				.filter(([, rules]) => rules.length > 0),
			...getUniqueFields(drawableFields)
				.map((field) => [field, getDrawableFieldConflictingRules(field)] as const)
				.filter(([, rules]) => rules.length > 0),
		]);

		return conflicts;
	}

	function getFieldWarning(field: BaseFormField, conflictingRules: AdmissionRule[]) {
		const conflictingRule = conflictingRules[0];

		if (conflictingRule.action === "requirement" || conflictingRule.action === "warning") {
			if (conflictingRule.entryField) {
				return texts.warningRequiredBecauseSet[language](field.name, [
					conflictingRule.entryFieldTitle as string,
				]);
			} else {
				return texts.warningRequired[language](field.name);
			}
		} else {
			return texts.warningExcludeBecauseSet[language](field.name, [
				conflictingRule.entryFieldTitle as string,
			]);
		}
	}

	function canEdit(field: BaseFormField) {
		if (field.person === undefined || field.person === "B") return true;
		if (field.person === "P" && user === "patient") return true;
		if (field.person === "S" && user === "employee") return true;
		return false;
	}

	const fieldConflicts = getFieldConflicts();
	const conflictFields = Array.from(fieldConflicts.keys());

	const filteredSteps = admissionProcess.steps.filter(shouldStepShow);

	const signotecPad = useSignotecPad();

	function cancelPadSignature() {
		if (!signotecPad) return;
		if (!myID) return;

		setFocusedElements({
			...focusedElements,
			[myID]: undefined,
		});

		window.STPadServerLibDefault.cancelSignature();
	}

	function retryPadSignature() {
		if (!signotecPad) return;

		window.STPadServerLibDefault.retrySignature();
	}

	async function confirmPadSignature() {
		if (!signotecPad) return;
		if (!selectedDrawableField) return;
		if (!myID) return;

		setFocusedElements({
			...focusedElements,
			[myID]: undefined,
		});

		const confirmSignatureResult = await window.STPadServerLibDefault.confirmSignature();

		if (confirmSignatureResult.countedPoints === 0) {
			setFormDrawableInput({
				...formDrawableInput,
				[selectedDrawableField.identifier]: undefined,
			});
			return;
		}

		const getSignatureImageParams = new window.STPadServerLibDefault.Params.getSignatureImage();
		const getSignatureImageResult = await window.STPadServerLibDefault.getSignatureImage(
			getSignatureImageParams,
		);

		setFormDrawableInput({
			...formDrawableInput,
			[selectedDrawableField.identifier]: "data:image/png;base64," + getSignatureImageResult.file,
		});
	}

	useEffect(() => {
		if (!selectedDrawableField || !signotecPad) return;

		const startSignatureParams = new window.STPadServerLibDefault.Params.startSignature();

		window.STPadServerLibDefault.startSignature(startSignatureParams);

		window.STPadServerLibDefault.handleCancelSignature = cancelPadSignature;
		window.STPadServerLibDefault.handleRetrySignature = retryPadSignature;
		window.STPadServerLibDefault.handleConfirmSignature = confirmPadSignature;

		return () => {
			window.STPadServerLibDefault.handleCancelSignature = undefined;
			window.STPadServerLibDefault.handleRetrySignature = undefined;
			window.STPadServerLibDefault.handleConfirmSignature = undefined;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedDrawableField, signotecPad]);

	const clientTrigger = useClientTrigger(channel);
	useEvent(channel, "client-process-complete", () => {
		navigate("/patient");
	});

	return (
		<div>
			<Header />
			<Container>
				{count === 2 ? (
					<>
						<div>
							{texts.loginProcess[language](key ?? "")}
							<Button
								variant={fieldConflicts.size > 0 ? "danger" : "success"}
								style={{ float: "right" }}
								onClick={() => {
									setShowMessages(true);
								}}>
								<FontAwesomeIcon
									icon={fieldConflicts.size > 0 ? faTriangleExclamation : faCheck}
									className="me-2"
								/>
								{texts.warningMessages[language]} ({fieldConflicts.size})
							</Button>
						</div>
						<div className="mt-3">
							<StepProgressBar
								currentStep={currentStep}
								onStepClicked={(step) =>
									setCurrentStep(admissionProcess.steps.indexOf(filteredSteps[step]))
								}
								steps={filteredSteps.map((step) => step.name)}
							/>
						</div>
						<div className="w-100">
							{admissionStep.pages.map((pageUrl, index) => (
								<Form
									className="form-page w-100"
									key={index}
									ref={(instance: HTMLFormElement | null) => (pagesRef.current[index] = instance)}>
									<img className="w-100" src={pageUrl} />
									{admissionStep.fields
										.filter((formField) => formField.page === index)
										.map((formField, index) =>
											formField.type !== "checkbox" ? (
												<Form.Control
													disabled={otherFormFocus === formField.identifier || !canEdit(formField)}
													className={
														otherFormFocus === formField.identifier
															? "form-field other-selected"
															: getFormFieldConflictingRules(formField).find(
																	(rule) =>
																		rule.action === "requirement" || rule.action === "warning",
															  )
															? "form-field required"
															: "form-field"
													}
													style={{
														top: `${formField.position.y}%`,
														left: `${formField.position.x}%`,
														width: `${formField.size.x}%`,
														height: `${formField.size.y}%`,
														textAlign: formField.textAlignment || "left",
														fontSize: isXl
															? "18px"
															: isLg
															? "16px"
															: isMd
															? "12px"
															: isSm
															? "9px"
															: "9px",
													}}
													type={formField.type}
													key={index}
													value={
														(formInput[formField.identifier] ??
															(formField.prefill && prefill(formField.prefill)) ??
															"") as string | string[] | number
													}
													onChange={(event) => {
														const value = event.target.value;
														setFormInput({ ...formInput, [formField.identifier]: value });
													}}
													onFocus={() => {
														if (!myID) return;

														setFocusedElements({
															...focusedElements,
															[myID]: formField.identifier,
														});
													}}
													onBlur={() => {
														if (!myID) return;

														setFocusedElements({
															...focusedElements,
															[myID]: undefined,
														});
													}}
													onKeyDown={(event) => {
														if (event.key.toLowerCase() === "enter") {
															// eslint-disable-next-line @typescript-eslint/ban-ts-comment
															// @ts-ignore
															const form = event.target.form;
															const index = [...form].indexOf(event.target);

															if (index === form.length - 1) return;

															form.elements[index + 1].focus();
															event.preventDefault();
														}
													}}
												/>
											) : (
												<input
													type="checkbox"
													className={
														getFormFieldConflictingRules(formField).find(
															(rule) => rule.action === "requirement" || rule.action === "warning",
														)
															? "form-field required"
															: "form-field"
													}
													style={{
														top: `${formField.position.y}%`,
														left: `${formField.position.x}%`,
														width: `${formField.size.x}%`,
														height: `${formField.size.y}%`,
													}}
													checked={
														formField.toggleGroupElement
															? formInput[formField.toggleGroupElement] === formField.identifier
															: !!formInput[formField.identifier]
													}
													onChange={() => {
														if (!canEdit(formField)) return;

														if (formField.toggleGroupElement) {
															if (
																formInput[formField.toggleGroupElement] !== formField.identifier
															) {
																setFormInput({
																	...formInput,
																	[formField.toggleGroupElement]: formField.identifier,
																});
															} else if (formField.toggleGroupDeselectable) {
																setFormInput({
																	...formInput,
																	[formField.toggleGroupElement]: undefined,
																});
															}
														} else {
															setFormInput({
																...formInput,
																[formField.identifier]: !formInput[formField.identifier],
															});
														}
													}}
													key={index}
													onKeyDown={(event) => {
														if (event.key.toLowerCase() === "enter") {
															// eslint-disable-next-line @typescript-eslint/ban-ts-comment
															// @ts-ignore
															const form = event.target.form;
															const index = [...form].indexOf(event.target);

															if (index === form.length - 1) return;

															form.elements[index + 1].focus();
															event.preventDefault();
														}
													}}
												/>
											),
										)}
									{admissionStep.drawableFields
										.filter((drawableField) => drawableField.page === index)
										.map((drawableField, index) => (
											<button
												disabled={!canEdit(drawableField)}
												type="button"
												className={
													getDrawableFieldConflictingRules(drawableField).find(
														(rule) => rule.action === "requirement" || rule.action === "warning",
													)
														? "form-field drawable required"
														: "form-field drawable"
												}
												style={{
													top: `${drawableField.position.y}%`,
													left: `${drawableField.position.x}%`,
													width: `${drawableField.size.x}%`,
													height: `${drawableField.size.y}%`,
												}}
												key={index}
												onClick={async () => {
													if (!myID) return;

													setFocusedElements({
														...focusedElements,
														[myID]: drawableField.identifier,
													});
												}}>
												{formDrawableInput[drawableField.identifier] !== undefined && (
													<img
														src={formDrawableInput[drawableField.identifier]}
														alt={drawableField.name}
														style={{
															position: "absolute",
															top: `0`,
															left: `0`,
															width: `100%`,
															height: `100%`,
														}}
													/>
												)}
											</button>
										))}
								</Form>
							))}
							<hr />
							<FormActions
								formData={admissionStep}
								formInput={formInput}
								formDrawableInput={formDrawableInput}
								showCompleteProcess={
									currentStep === admissionProcess.steps.length - 1 && user === "employee"
								}
								canCompleteProcess={fieldConflicts.size === 0}
								completeProcess={() => {
									clientTrigger("client-process-complete", {});
									navigate("/employee");
								}}
							/>
							<Modal
								size="lg"
								fullscreen="lg-down"
								aria-labelledby="contained-modal-title-vcenter"
								centered
								show={selectedDrawableField !== undefined}
								onHide={() => {
									if (signotecPad) {
										cancelPadSignature();
										return;
									}

									if (!myID) return;
									setFocusedElements({
										...focusedElements,
										[myID]: undefined,
									});
								}}>
								<Modal.Header closeButton>
									<Modal.Title id="contained-modal-title-vcenter">
										<FontAwesomeIcon icon={faPencil} className="me-2" />
										{texts.signature[language]}
									</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									<p>
										{signotecPad
											? texts.signatureTextSignotec[language]
											: texts.signatureTextOnscreen[language]}
									</p>
									{selectedDrawableField && !signotecPad && (
										<CanvasDraw
											immediateLoading={true}
											brushRadius={2}
											lazyRadius={1}
											enablePanAndZoom={false}
											gridLineWidth={1}
											ref={canvasDrawRef}
											canvasWidth={600}
											canvasHeight={Math.max(
												150,
												(selectedDrawableField.size.y / selectedDrawableField.size.x) * 600,
											)}
										/>
									)}
								</Modal.Body>
								<Modal.Footer>
									<Button
										variant="outline-danger"
										onClick={() => {
											if (signotecPad) {
												retryPadSignature();
												return;
											}

											canvasDrawRef.current?.clear();
										}}>
										<FontAwesomeIcon icon={faEraser} className="me-2" />
										{signotecPad
											? texts.retrySignatureButton[language]
											: texts.resetSignatureButton[language]}
									</Button>
									<Button
										variant="success"
										onClick={() => {
											if (signotecPad) {
												confirmPadSignature();
												return;
											}

											if (!myID) return;
											if (!selectedDrawableField) return;
											if (!canvasDrawRef.current) return;

											interface GetDataUrl {
												getDataURL(fileType: string): string;
											}

											const imageData = (
												canvasDrawRef.current as CanvasDraw & GetDataUrl
											).getDataURL("png") as string;

											const isDrawn =
												JSON.parse(canvasDrawRef.current.getSaveData()).lines.length > 0;

											setFormDrawableInput({
												...formDrawableInput,
												[selectedDrawableField.identifier]: isDrawn ? imageData : undefined,
											});

											setFocusedElements({
												...focusedElements,
												[myID]: undefined,
											});
										}}>
										<FontAwesomeIcon icon={faCheck} className="me-2" />
										{texts.saveSignatureButton[language]}
									</Button>
								</Modal.Footer>
							</Modal>
							<Modal
								size="lg"
								fullscreen="lg-down"
								aria-labelledby="contained-modal-title-vcenter"
								centered
								show={showMessages}
								onHide={() => {
									setShowMessages(false);
								}}>
								<Modal.Header closeButton>
									<Modal.Title id="contained-modal-title-vcenter">
										<FontAwesomeIcon icon={faTriangleExclamation} className="me-2" />
										{texts.warningMessages[language]}
									</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									{fieldConflicts.size > 0 ? (
										admissionProcess.steps
											.filter((step) =>
												conflictFields.find(
													(conflictField) =>
														step.fields.includes(conflictField as FormField) ||
														step.drawableFields.includes(conflictField as DrawableField),
												),
											)
											.map((step, index) => (
												<div key={index}>
													<p className="mb-1 mt-2">
														<FontAwesomeIcon icon={faFileContract} className="me-2" />
														<b>{step.name}</b>
													</p>
													<ListGroup as="ol">
														{conflictFields
															.filter(
																(conflictField) =>
																	step.fields.includes(conflictField as FormField) ||
																	step.drawableFields.includes(conflictField as DrawableField),
															)
															.map((conflictField) => (
																<ListGroup.Item
																	className="warning-info"
																	action
																	as="li"
																	key={conflictField.uid}
																	onClick={() => {
																		const formFieldStep = admissionProcess.steps.findIndex((step) =>
																			step.fields.includes(conflictField as FormField),
																		);

																		const drawableFieldStep = admissionProcess.steps.findIndex(
																			(step) =>
																				step.drawableFields.includes(
																					conflictField as DrawableField,
																				),
																		);

																		const step =
																			formFieldStep !== -1
																				? formFieldStep
																				: drawableFieldStep !== -1
																				? drawableFieldStep
																				: undefined;
																		if (step === undefined) return;

																		setCurrentStep(step);
																		setShowMessages(false);

																		window.setTimeout(() => {
																			const rect =
																				pagesRef.current[
																					conflictField.page
																				]?.getBoundingClientRect();
																			if (!rect) return;

																			const targetX =
																				rect.left +
																				rect.width * (conflictField.position.x / 100) -
																				150;
																			const targetY =
																				rect.top +
																				rect.height * (conflictField.position.y / 100) -
																				150;

																			window.scrollTo(targetX, targetY);
																		}, 100);
																	}}>
																	{getFieldWarning(
																		conflictField,
																		fieldConflicts.get(conflictField) as AdmissionRule[],
																	)}
																</ListGroup.Item>
															))}
													</ListGroup>
												</div>
											))
									) : (
										<div>
											<FontAwesomeIcon icon={faCheck} className="me-2" />
											<b> {texts.noWarnings[language]} </b>
										</div>
									)}
								</Modal.Body>
							</Modal>
						</div>
					</>
				) : (
					<Alert variant="success">
						<Spinner size="sm" animation="border" variant="success" />
						<span> {texts.connectingMessage[language]} </span>
					</Alert>
				)}
			</Container>
		</div>
	);
}
