import { faUser, faUserDoctor } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { useContext } from "react";
import ActionCard from "../components/userCard";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Header from "../components/header";
import LanguageContext from "../contexts/languageContext";
import Row from "react-bootstrap/Row";
import TextsContext from "../contexts/textsContext";

library.add(faUser, faUserDoctor);

export default function App() {
	const texts = useContext(TextsContext);
	const { language } = useContext(LanguageContext);

	return (
		<div>
			<Header />
			<Container>
				<Row>
					<Col xs={12} md={6}>
						<ActionCard
							name={texts.patient[language]}
							text={texts.loginPatient[language]}
							icon={faUser}
							link="patient"
						/>
					</Col>
					<Col xs={12} md={6}>
						<ActionCard
							name={texts.employee[language]}
							text={texts.loginEmployee[language]}
							icon={faUserDoctor}
							link="employee"
						/>
					</Col>
				</Row>
			</Container>
		</div>
	);
}
