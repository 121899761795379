import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormData, FormFieldValue } from "../types/admissionTypes";
import { PDFDocument, StandardFonts } from "pdf-lib";
import { PropsWithChildren, useContext } from "react";
import { faCheck, faXmark } from "@fortawesome/pro-light-svg-icons";
import { faDownload, faPrint } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { prefill } from "../prefillSystem";
import { useAuth } from "oidc-react";
import Button from "react-bootstrap/Button";
import LanguageContext from "../contexts/languageContext";
import TextsContext from "../contexts/textsContext";
import printJS from "print-js";

library.add(faCheck, faXmark, faPrint, faDownload);

function downloadFile(blob: Blob, fileName: string) {
	const link = document.createElement("a");

	// create a blobURI pointing to our Blob
	link.href = URL.createObjectURL(blob);
	link.download = fileName;

	// some browsers need the anchor to be in the doc
	document.body.append(link);
	link.click();
	link.remove();

	setTimeout(() => URL.revokeObjectURL(link.href), 7000);
}

function replaceStringAt(string: string, index: number, replacement: string) {
	return string.substring(0, index) + replacement + string.substring(index + replacement.length);
}

export interface FormActionsProps {
	formData: FormData;
	formInput: Record<string, FormFieldValue>;
	formDrawableInput: Record<string, string | undefined>;
	showCompleteProcess: boolean;
	canCompleteProcess: boolean;
	completeProcess: () => void;
}

export default function FormActions(props: PropsWithChildren<FormActionsProps>) {
	const texts = useContext(TextsContext);
	const { language } = useContext(LanguageContext);

	const auth = useAuth();

	async function getPdfBlob() {
		const existingPdfBytes = await fetch(props.formData.pdf, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${auth.userData?.access_token}`,
			},
		}).then((res) => res.arrayBuffer());

		const pdfDoc = await PDFDocument.load(existingPdfBytes, {
			updateMetadata: false,
		});

		const inputFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

		const form = pdfDoc.getForm();
		const pages = pdfDoc.getPages();

		const allowedChars = inputFont.getCharacterSet();

		function replaceUnknownCharacters(string = "") {
			for (let index = 0; index < string.length; index++) {
				const unicode = string.charCodeAt(index);
				if (!allowedChars.includes(unicode)) {
					string = replaceStringAt(string, index, "?");
				}
			}
			return string;
		}

		props.formData.drawableFields.forEach(async (field) => {
			const page = pages[field.page];

			const drawableInput = props.formDrawableInput[field.identifier];
			if (!drawableInput) return;

			const pngImage = await pdfDoc.embedPng(drawableInput);

			page.drawImage(pngImage, {
				x: (field.position.x / 100 - field.size.x / 100 / 2) * page.getWidth(),
				y: ((100 - field.position.y) / 100 - field.size.y / 100 / 2) * page.getHeight(),

				width: (field.size.x / 100) * page.getWidth(),
				height: (field.size.y / 100) * page.getHeight(),
			});
		});

		props.formData.fields.forEach((field) => {
			const page = pages[field.page];

			if (field.type === "checkbox" && field.toggleGroupElement) {
				if (field.toggleGroupElement !== field.identifier) return; // Handled by main element

				const radioGroup = form.createRadioGroup(field.uid);

				const connectedFields = props.formData.fields.filter(
					(connectedField) => connectedField.toggleGroupElement === field.identifier,
				);

				connectedFields.forEach((connectedField) => {
					radioGroup.addOptionToPage(connectedField.uid, page, {
						x:
							(connectedField.position.x / 100 - connectedField.size.x / 100 / 2) * page.getWidth(),
						y:
							((100 - connectedField.position.y) / 100 - connectedField.size.y / 100 / 2) *
							page.getHeight(),

						width: (connectedField.size.x / 100) * page.getWidth(),
						height: (connectedField.size.y / 100) * page.getHeight(),
					});
				});

				const input = props.formInput[field.identifier];
				if (typeof input !== "string") return;
				radioGroup.select(input);
			} else if (field.type === "checkbox") {
				const checkbox = form.createCheckBox(field.uid);
				if (props.formInput[field.identifier]) checkbox.check();
				else checkbox.uncheck();

				checkbox.addToPage(page, {
					x: (field.position.x / 100 - field.size.x / 100 / 2) * page.getWidth(),
					y: ((100 - field.position.y) / 100 - field.size.y / 100 / 2) * page.getHeight(),

					width: (field.size.x / 100) * page.getWidth(),
					height: (field.size.y / 100) * page.getHeight(),
				});
			} else {
				const fieldInput =
					props.formInput[field.identifier] ?? (field.prefill && prefill(field.prefill));

				const pdfField = form.createTextField(field.uid);
				pdfField.setText(replaceUnknownCharacters(fieldInput?.toString()));

				pdfField.addToPage(page, {
					x: (field.position.x / 100 - field.size.x / 100 / 2) * page.getWidth(),
					y: ((100 - field.position.y) / 100 - field.size.y / 100 / 2) * page.getHeight(),

					width: (field.size.x / 100) * page.getWidth(),
					height: (field.size.y / 100) * page.getHeight(),

					font: inputFont,
				});
			}
		});

		form.flatten();

		const pdfBytes = await pdfDoc.save();

		const blob = new Blob([pdfBytes], { type: "application/pdf" });

		return blob;
	}

	return (
		<div className="mb-3 mt-3">
			<Button
				variant="success"
				onClick={async () => {
					const pdfBlob = await getPdfBlob();
					downloadFile(pdfBlob, `${props.formData.name}.pdf`);
				}}>
				<FontAwesomeIcon icon={faDownload} className="me-2" />
				{texts.exportFormButton[language]}
			</Button>
			<Button
				variant="primary"
				className="ms-2"
				onClick={async () => {
					const pdfBlob = await getPdfBlob();

					const url = URL.createObjectURL(pdfBlob);
					printJS(url, "pdf");

					setTimeout(() => URL.revokeObjectURL(url), 7000);
				}}>
				<FontAwesomeIcon icon={faPrint} className="me-2" />
				{texts.printFormButton[language]}
			</Button>
			<Button
				variant={props.canCompleteProcess ? "success" : "danger"}
				style={{ float: "right", display: props.showCompleteProcess ? undefined : "none" }}
				onClick={() => props.completeProcess()}>
				<FontAwesomeIcon icon={props.canCompleteProcess ? faCheck : faXmark} className="me-2" />
				{props.canCompleteProcess
					? texts.completeProcessButton[language]
					: texts.abortProcessButton[language]}
			</Button>
		</div>
	);
}
