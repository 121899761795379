import { BaseArgs } from "./baseArgs";

export interface ClinicArgs extends BaseArgs {
	clinicId: number;
}

export interface ClinicData {
	data: Clinic;
}

export interface Clinic {
	id: number;
	name: string;
	wards: Ward[];
}

export interface Ward {
	id: number;
	name: string;
	clinic_id: number;
}

export default async function fetchClinic(args: ClinicArgs) {
	const response = await fetch(
		`https://${args.tenant}.${args.env}.medix.care/api/v1/admission/clinic/${args.clinicId}`,
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${args.token}`,
			},
		},
	);

	return (await response.json()) as ClinicData;
}
