/**
 * This function replaces a string and only returns if all replacements were successful.
 * @param stringWithPlaceholders The string with placeholders in curly braces.
 * @param replacements The replacements to replace the placeholders with.
 * @returns The string with the replacements if all replacements were successful.
 */
export function replace(
	stringWithPlaceholders: string,
	replacements: Record<string, string | undefined>,
) {
	let fullyReplaced = true;

	const string = stringWithPlaceholders.replace(
		/\{([^)]+)\}/,
		(placeholderWithDelimiters, placeholderWithoutDelimiters) => {
			if (placeholderWithoutDelimiters in replacements) {
				return replacements[placeholderWithoutDelimiters] as string;
			} else {
				fullyReplaced = false;
				return placeholderWithDelimiters;
			}
		},
	);

	return fullyReplaced ? string : undefined;
}

export function prefill(string: string) {
	const prefills = getAvailablePrefills();
	return replace(string, prefills);
}

export function getAvailablePrefills(): Record<string, string | undefined> {
	return {
		// Firstname: "Richard",
		// Lastname: "Neumann",
		current_date: new Date().toISOString().split("T")[0],
	};
}
