import { BaseArgs } from "./baseArgs";

export interface ProcessListData {
	data: Process[];
}

export interface Process {
	id: number;
	name: string;
	valid_from: string;
	clinics: Clinic[];
	process_identifiers: ProcessIdentifier[];
	steps_count: number;
	clinics_count: number;
	wards_count: number;
}

export interface Clinic {
	id: number;
	name: string;
}

export interface ProcessIdentifier {
	id: number;
	name: string;
	order: number | null;
}

export default async function fetchProcessList(args: BaseArgs) {
	const response = await fetch(
		`https://${args.tenant}.${args.env}.medix.care/api/v1/admission/process/`,
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${args.token}`,
			},
		},
	);

	return (await response.json()) as ProcessListData;
}
