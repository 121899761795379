import { BaseArgs } from "./baseArgs";

export interface ProcessIdentifierGroupsData {
	data: ProcessIdentifierGroup[];
}

export interface ProcessIdentifierGroup {
	id: number;
	name: string;
	order: number;
}

export default async function fetchProcessIdentifierGroups(args: BaseArgs) {
	const response = await fetch(
		`https://${args.tenant}.${args.env}.medix.care/api/v1/admission/identifier-group`,
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${args.token}`,
			},
		},
	);

	return (await response.json()) as ProcessIdentifierGroupsData;
}
