import { Channel } from "pusher-js";
import { useChunkedClientTrigger } from "./useChunkedClientTrigger";
import { useState } from "react";
import { useUpdateEffect } from "usehooks-ts";
import useChunkedEvent from "./useChunkedEvent";

const SYNCED_STATE_PREFIX = "client-synced-state-";

export default function useSyncedState<T>(
	initialState: T,
	key: string,
	channel: Channel | undefined,
	chunked = false,
) {
	const [state, setState] = useState<T>(initialState);
	const [source, setSource] = useState<"local" | "remote">("local");

	const clientTrigger = useChunkedClientTrigger<{ value: T }>(channel, chunked);

	useChunkedEvent<{ value: T }>(
		channel,
		SYNCED_STATE_PREFIX + key,
		(data) => {
			if (!data) return;
			setState(data.value);
			setSource("remote");
		},
		chunked,
	);

	useUpdateEffect(() => {
		if (source === "local") {
			clientTrigger(SYNCED_STATE_PREFIX + key, { value: state });
		}
	}, [state, source]);

	function setSyncedState(value: React.SetStateAction<T>) {
		setState(value);
		setSource("local");
	}

	return [state, setSyncedState] as const;
}
