import {
	AdmissionProcess,
	AdmissionRule,
	AdmissionStep,
	BaseFormField,
	DrawableField,
	FormField,
	FormFieldType,
} from "../types/admissionTypes";
import { BaseArgs } from "./baseArgs";
import { Document, Field, FieldType, Positioning } from "./fetchDocument";
import { Process, Rule, Step } from "./fetchProcess";
import { Vector2 } from "../types/vector";

function sortField(a: BaseFormField, b: BaseFormField) {
	// The Y-Axis is valued 10x more when considering the order
	const aVector = a.position.scaleY(10);
	const bVector = b.position.scaleY(10);

	return aVector.magnitude - bVector.magnitude;
}

export function convertProcess(args: BaseArgs, process: Process, documents: Document[]) {
	function getFieldType(field: Field): FormFieldType | "drawable" {
		switch (field.type) {
			case FieldType.PDFPatientSignature:
				return "drawable";
			case FieldType.PDFClinicSignature:
				return "drawable";
			case FieldType.FOPDateField:
				return "date";
			case FieldType.PDFCheckBox:
				return "checkbox";
			case FieldType.PDFRadioGroup:
				return "checkbox";
			case FieldType.PDFDeselectRadioGroup:
				return "checkbox";
			case FieldType.PDFTextField:
				return "text";
			default:
				console.warn("Unknown field type:", field.type);
				return "text";
		}
	}

	function convertRule(rule: Rule) {
		const admissionRule: AdmissionRule = {
			action: rule.action,
			entryCondition: rule.entryCondition,
			entryField: rule.entryField.id.toString(),
			entryFieldTitle: rule.entryField.title,
		};

		return admissionRule;
	}

	function getSize(position: Positioning, doc: Document) {
		const page = doc.pages[position.page - 1];

		return new Vector2(position.width * (100 / page.width), position.height * (100 / page.height));
	}
	function getPosition(position: Positioning, doc: Document) {
		const page = doc.pages[position.page - 1];

		const size = getSize(position, doc);

		return new Vector2(
			position.x * (100 / page.width) + size.x / 2,
			100 - position.y * (100 / page.height) - size.y / 2,
		);
	}

	function convertField(
		field: Field,
		doc: Document,
	): { isDrawable: true; field: DrawableField } | { isDrawable: false; field: FormField[] } {
		const position = field.positioning[0];

		const type = getFieldType(field);

		const affectingRules = process.rules
			.filter((rule) => rule.effecting.id === field.id && rule.effecting_type === "docfield")
			.map(convertRule);

		const isRequired = field.conditions === 1 || field.conditions === 2 || field.conditions === 3;

		if (isRequired) {
			affectingRules.push({
				action: "requirement",
				entryCondition: "filled",
			});
		}

		const baseField: BaseFormField = {
			uid: field.id.toString(),
			identifier: field.prefill ?? field.id.toString(),
			name: field.title,
			person: field.person ?? undefined,
			position: getPosition(position, doc),
			size: getSize(position, doc),
			page: position.page - 1,
			affectingRules: affectingRules,
		};

		if (type === "drawable") {
			return { isDrawable: true, field: baseField };
		} else {
			if (
				field.type === FieldType.PDFRadioGroup ||
				field.type === FieldType.PDFDeselectRadioGroup
			) {
				const formFields = field.positioning.map((position, index) => {
					const formField: FormField = {
						...baseField,
						uid: field.id.toString() + index,
						identifier: (field.prefill ?? field.id.toString()) + index,
						type: type,
						prefill: field.prefill ?? undefined,
						toggleGroupElement: (field.prefill ?? field.id.toString()) + 0,
						toggleGroupDeselectable: field.type === FieldType.PDFDeselectRadioGroup,
						position: getPosition(position, doc),
						size: getSize(position, doc),
						page: position.page - 1,
					};

					return formField;
				});

				return { isDrawable: false, field: formFields };
			} else {
				const formField: FormField = {
					...baseField,
					type: type,
					prefill: field.prefill ?? undefined,
				};
				return { isDrawable: false, field: [formField] };
			}
		}
	}

	function convertStep(step: Step) {
		const doc = documents.find((doc) => doc.id === step.document_id);
		if (!doc) throw `Document ${step.document_id} is missing`;

		const fields = doc.fields.map((field) => convertField(field, doc));

		const affectingRules = process.rules
			.filter(
				(rule) =>
					(rule.effecting.id === step.document.id && rule.effecting_type === "doc") ||
					(rule.effecting.document_id === step.document.id && rule.effecting_type === "docfield"),
			)
			.map(convertRule);

		const convertedStep: AdmissionStep = {
			name: step.name,
			hidden: step.is_hidden,
			affectingRules: affectingRules,
			drawableFields: fields
				.map(({ isDrawable, field }) => {
					return isDrawable ? field : undefined;
				})
				.filter((field): field is DrawableField => !!field)
				.sort(sortField),
			fields: fields
				.map(({ isDrawable, field }) => {
					return !isDrawable ? field : undefined;
				})
				.flat()
				.filter((field): field is FormField => !!field)
				.sort(sortField),
			pages: doc.pages.map(
				(page) =>
					`https://${args.tenant}.${args.env}.medix.care/api/v1/public/document/${doc.id}/page?page_number=${page.page_number}`,
			),
			pdf: `https://${args.tenant}.${args.env}.medix.care/api/v1/admission/document/${doc.id}/pdf`,
		};
		return convertedStep;
	}

	const convertedProcess: AdmissionProcess = {
		id: process.id,
		name: process.name,
		steps: process.steps.map((step) => convertStep(step)),
	};

	return convertedProcess;
}
