import React from "react";

export default React.createContext({
	patient: {
		de: "Patient",
		en: "Patient",
		es: "Paciente",
		fr: "Patient",
	},
	employee: {
		de: "Mitarbeiter",
		en: "Employee",
		es: "Empleados",
		fr: "Employés",
	},
	loginPatient: {
		de: "Klicken Sie hier, um sich als Patient anzumelden",
		en: "Click here to login as a patient",
		es: "Haga clic aquí para registrarse como paciente",
		fr: "Cliquez ici pour vous inscrire en tant que patient",
	},
	loginEmployee: {
		de: "Klicken Sie hier, um sich als Mitarbeiter anzumelden",
		en: "Click here to login as an employee",
		es: "Haga clic aquí para registrarse como empleado",
		fr: "Cliquez ici pour vous inscrire en tant que collaborateur",
	},
	error404: {
		de: "Fehler 404",
		en: "Error 404",
		es: "Error 404",
		fr: "Erreur 404",
	},
	error404Message: {
		de: "Die von Ihnen aufgerufene Seite existiert nicht.",
		en: "The page you are looking for does not exist.",
		es: "La página solicitada no existe.",
		fr: "La page que vous avez consultée n'existe pas.",
	},
	headerTitle: {
		de: "Patientenaufnahme",
		en: "Patient Admission",
		es: "Admisión de pacientes",
		fr: "Accueil des patients",
	},
	connectingMessage: {
		de: "Verbinden...",
		en: "Connecting...",
		es: "Conectando...",
		fr: "Connexion...",
	},
	waitingForEmployeeMessage: {
		de: "Warten auf Mitarbeiter...",
		en: "Waiting for employee...",
		es: "Esperando al personal...",
		fr: "Attendre des collaborateurs...",
	},
	waitingForPatientsMessage: {
		de: "Warten auf Patienten...",
		en: "Waiting for patients...",
		es: "Esperando a los pacientes...",
		fr: "Attendre les patients...",
	},
	loadingPdfMessage: {
		de: "PDF wird geladen...",
		en: "PDF is loading...",
		es: "El PDF está cargado...",
		fr: "Chargement du PDF...",
	},
	loggedInAs: {
		de: (name: string) => `Angemeldet als ${name}`,
		en: (name: string) => `Logged in as ${name}`,
		es: (name: string) => `Registrado como ${name}`,
		fr: (name: string) => `Connecté en tant que ${name}`,
	},
	loginProcess: {
		de: (processName: string) => `Anmeldeprozess: ${processName}`,
		en: (processName: string) => `Login Process: ${processName}`,
		es: (processName: string) => `Proceso de registro: ${processName}`,
		fr: (processName: string) => `Processus d'inscription: ${processName}`,
	},
	language: {
		de: "Sprache",
		en: "Language",
		es: "Idioma",
		fr: "Langue",
	},
	exportFormButton: {
		de: "Formular exportieren",
		en: "Export form",
		es: "Formulario de exportación",
		fr: "Exporter le formulaire",
	},
	printFormButton: {
		de: "Formular drucken",
		en: "Print form",
		es: "Imprimir formulario",
		fr: "Imprimer le formulaire",
	},
	completeProcessButton: {
		de: "Prozess abschließen",
		en: "Complete process",
		es: "Proceso completo",
		fr: "Clôturer le processus",
	},
	abortProcessButton: {
		de: "Prozess abbrechen",
		en: "Abort process",
		es: "Cancelar proceso",
		fr: "Annuler le processus",
	},
	signature: {
		de: "Unterschrift",
		en: "Signature",
		es: "Firma",
		fr: "Signature",
	},
	signatureTextOnscreen: {
		de: "Bitte unterschreiben Sie unten auf der größe des ganzen Feldes. Sie können mit Ihrer Maus oder Touchpad Ihre Unterschrift zeichnen.",
		en: "Please sign below on the full size of the field. You can draw your signature with your mouse or touchpad.",
		es: "Por favor, firme abajo en el tamaño de todo el campo. Puedes dibujar tu firma con el ratón o el touchpad.",
		fr: "Veuillez signer en bas de la page, à la taille de la case entière. Vous pouvez utiliser votre souris ou votre touchpad pour dessiner votre signature.",
	},
	signatureTextSignotec: {
		de: "Bitte unterschreiben Sie auf dem verbundenen Unterschriftenpad.",
		en: "Please sign on the connected signature pad.",
		es: "Firme en la libreta de firmas.",
		fr: "Veuillez signer sur le pad de signature connecté.",
	},
	resetSignatureButton: {
		de: "Zurücksetzen",
		en: "Reset",
		es: "Restablecer",
		fr: "Réinitialiser",
	},
	retrySignatureButton: {
		de: "Wiederholen",
		en: "Retry",
		es: "Reintentar",
		fr: "Retry",
	},
	saveSignatureButton: {
		de: "Speichern",
		en: "Save",
		es: "Guardar",
		fr: "Enregistrer",
	},
	warningMessages: {
		de: "Warnmeldungen",
		en: "Warning Messages",
		es: "Mensajes de alerta",
		fr: "Messages d'alerte",
	},
	warningRequired: {
		de: (fieldName: string) => `Das Feld "${fieldName}" ist ein Pflichtfeld.`,
		en: (fieldName: string) => `The "${fieldName}" field is required.`,
		es: (fieldName: string) => `El campo "${fieldName}" es obligatorio.`,
		fr: (fieldName: string) => `Le champ "${fieldName}" est un champ obligatoire.`,
	},
	warningRequiredBecauseSet: {
		de: (fieldName: string, otherFields: string[]) =>
			`Das Feld "${fieldName}" ist ein Pflichtfeld, da ${
				otherFields.length === 1 ? "das Feld" : "die Felder"
			} "${otherFields.join(",")}" gesetzt ${otherFields.length === 1 ? "ist" : "sind"}.`,
		en: (fieldName: string, otherFields: string[]) =>
			`The "${fieldName}" field is required, because ${
				otherFields.length === 1 ? "the field" : "the fields"
			} "${otherFields.join(", ")}" ${otherFields.length === 1 ? "is" : "are"} set.`,
		es: (fieldName: string, otherFields: string[]) =>
			`El campo "${fieldName}" es un campo obligatorio porque el campo "${otherFields.join(
				", ",
			)}" está configurado.`,
		fr: (fieldName: string, otherFields: string[]) =>
			`Le champ "${fieldName}" est un champ obligatoire, car ${
				otherFields.length === 1 ? "le champ" : "les champs"
			} "${otherFields.join(", ")}" ${otherFields.length === 1 ? "est défini." : "sont définis."}`,
	},
	warningExcludeBecauseSet: {
		de: (fieldName: string, otherFields: string[]) =>
			`Das Feld "${fieldName}" darf nicht gesetzt werden, da ${
				otherFields.length === 1 ? "das Feld" : "die Felder"
			} "${otherFields.join(",")}" gesetzt ${otherFields.length === 1 ? "ist" : "sind"}.`,
		en: (fieldName: string, otherFields: string[]) =>
			`The "${fieldName}" field may not be set, because ${
				otherFields.length === 1 ? "the field" : "the fields"
			} "${otherFields.join(", ")}" ${otherFields.length === 1 ? "is" : "are"} set.`,
		es: (fieldName: string, otherFields: string[]) =>
			`El campo "${fieldName}" no debe estar configurado porque el campo "${otherFields.join(
				", ",
			)}" está configurado.`,
		fr: (fieldName: string, otherFields: string[]) =>
			`Le champ "${fieldName}" ne doit pas être activé, car ${
				otherFields.length === 1 ? "le champ" : "les champs"
			} "${otherFields.join(", ")}" ${otherFields.length === 1 ? "est défini." : "sont définis."}`,
	},
	noWarnings: {
		de: "Keine Warnmeldungen",
		en: "No warning messages",
		es: "Sin Mensajes de alerta",
		fr: "Aucun Messages d'alerte",
	},
	selectProcessIdentifier: {
		de: (processIdentifier: string) => `${processIdentifier} auswählen`,
		en: (processIdentifier: string) => `Select ${processIdentifier}`,
		es: (processIdentifier: string) => `Seleccione ${processIdentifier}`,
		fr: (processIdentifier: string) => `Sélectionner ${processIdentifier}`,
	},
	signOutButton: {
		de: "Abmelden",
		en: "Sign out",
		es: "Cerrar sesión",
		fr: "Se désinscrire",
	},
});
