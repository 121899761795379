import { BaseArgs } from "./baseArgs";

export interface ProcessArgs extends BaseArgs {
	processId: number;
}

export interface ProcessData {
	process: Process;
}

export interface Process {
	id: number;
	name: string;
	valid_from: string;
	clinics: unknown[];
	rules: Rule[];
	wards: unknown[];
	process_identifiers: ProcessIdentifier[];
	steps: Step[];
}

export interface ProcessIdentifier {
	id: number;
	name: string;
	order: number | null;
}

export interface Rule {
	id: number;
	process_id: number;
	name: string | null;
	description: string | null;
	action: "requirement" | "warning" | "exclude";
	entryField: {
		id: number;
		title: string;
	};
	entryCondition: "filled";
	effecting: {
		id: number;
		document_id?: number;
	};
	effecting_type: "docfield" | "doc";
}

export interface Step {
	name: string;
	step: number;
	valid_from: string;
	process_id: number;
	document_id: number;
	is_optional: boolean;
	is_hidden: boolean;
	document: Document;
}

export interface Document {
	id: number;
	name: string;
	is_optional: boolean;
	clinic_id: number | null;
	ward_id: number | null;
	process_step_id: number | null;
}

export default async function fetchProcess(args: ProcessArgs) {
	const response = await fetch(
		`https://${args.tenant}.${args.env}.medix.care/api/v1/admission/process/${args.processId}`,
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${args.token}`,
			},
		},
	);

	return (await response.json()) as ProcessData;
}
