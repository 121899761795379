import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-light-svg-icons";
import { faGlobe, faRightFromBracket, faUser } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { useAuth } from "oidc-react";
import { useContext } from "react";
import { useNavigate } from "react-router";
import Container from "react-bootstrap/Container";
import LanguageContext from "../contexts/languageContext";
import NavDropdown from "react-bootstrap/NavDropdown";
import Navbar from "react-bootstrap/Navbar";
import TextsContext from "../contexts/textsContext";
import "../styles/header.css";
import "/node_modules/flag-icons/css/flag-icons.min.css";

library.add(faArrowLeft, faUser, faGlobe, faRightFromBracket);

export default function Header() {
	const texts = useContext(TextsContext);
	const { language, setLanguage } = useContext(LanguageContext);

	const navigate = useNavigate();

	const auth = useAuth();

	return (
		<Navbar className="mb-3 sticky-top" variant="light" bg="light">
			<Container>
				<Navbar.Brand>
					<FontAwesomeIcon
						className="mx-3 header-back"
						icon={faArrowLeft}
						size="2x"
						onClick={() => {
							navigate(-1);
						}}
					/>
					<span className="header-title">{texts.headerTitle[language]}</span>
				</Navbar.Brand>
				<Navbar.Collapse className="justify-content-end">
					<NavDropdown
						title={
							<span>
								{auth.userData?.profile.name}
								<FontAwesomeIcon icon={faUser} className="ms-1" />
							</span>
						}
						menuVariant="dark"
						className="mx-4">
						<NavDropdown.Item
							color=""
							onClick={() => {
								auth.signOutRedirect("/");
							}}>
							<span>
								<FontAwesomeIcon icon={faRightFromBracket} className="me-1" />
								{texts.signOutButton[language]}
							</span>
						</NavDropdown.Item>
					</NavDropdown>
					<NavDropdown
						title={
							<span>
								{texts.language[language]}
								<FontAwesomeIcon icon={faGlobe} className="ms-1" />
							</span>
						}
						menuVariant="dark">
						<NavDropdown.Item
							color=""
							active={language === "de"}
							onClick={() => {
								setLanguage("de");
							}}>
							<span className="fi fi-de" /> Deutsch
						</NavDropdown.Item>
						<NavDropdown.Item
							active={language === "en"}
							onClick={() => {
								setLanguage("en");
							}}>
							<span className="fi fi-gb" /> English
						</NavDropdown.Item>
						<NavDropdown.Item
							active={language === "es"}
							onClick={() => {
								setLanguage("es");
							}}>
							<span className="fi fi-es" /> Español
						</NavDropdown.Item>
						<NavDropdown.Item
							active={language === "fr"}
							onClick={() => {
								setLanguage("fr");
							}}>
							<span className="fi fi-fr" /> Français
						</NavDropdown.Item>
					</NavDropdown>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
}
