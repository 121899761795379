import { faHospital } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { useAuth } from "oidc-react";
import { useClientTrigger, useEvent, usePresenceChannel } from "@harelpls/use-pusher";
import { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { useQueries, useQuery } from "react-query";
import ActionCard from "../components/userCard";
import Alert from "react-bootstrap/Alert";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import EnvContext from "../contexts/envContext";
import Header from "../components/header";
import LanguageContext from "../contexts/languageContext";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import TextsContext from "../contexts/textsContext";
import fetchClinic from "../api/fetchClinic";
import fetchProcessIdentifierGroup, {
	ProcessIdentifierGroup,
} from "../api/fetchProcessIdentifierGroup";
import fetchProcessIdentifierGroups from "../api/fetchProcessIdentifierGroups";
import fetchProcessList from "../api/fetchProcessList";
import useSyncedState from "../hooks/useSyncedState";
import "../styles/edit.css";

library.add(faHospital);

export default function Edit() {
	const navigate = useNavigate();

	const { user, key } = useParams();

	const { tenant, env } = useContext(EnvContext);
	const texts = useContext(TextsContext);
	const { language } = useContext(LanguageContext);

	const auth = useAuth();

	const { channel, count, members, myID } = usePresenceChannel(`presence-edit-${key}`);

	useEvent<{ process: number }>(channel, "client-process-start", (data) => {
		if (!data) return;
		navigate(`/edit/patient/${key}/${data.process}`);
	});

	const processTrigger = useClientTrigger<{ process: number }>(channel);

	const otherID = members && Object.entries(members).find(([member]) => member !== myID)?.[0];

	const clinic = useQuery("clinic", () =>
		fetchClinic({
			clinicId: 1,
			env,
			tenant,
			token: auth.userData?.access_token ?? "",
		}),
	);

	const processIdentifierList = useQuery("processIdentifierList", () =>
		fetchProcessIdentifierGroups({
			env,
			tenant,
			token: auth.userData?.access_token ?? "",
		}),
	);

	const processIdentifiers = useQueries(
		processIdentifierList.data
			? processIdentifierList.data.data.map((processIdentifier) => {
					return {
						queryKey: `processIdentifier${processIdentifier.id}`,
						queryFn: () =>
							fetchProcessIdentifierGroup({
								processIdentifierId: processIdentifier.id,
								env,
								tenant,
								token: auth.userData?.access_token ?? "",
							}),
					};
			  })
			: [],
	);

	const processList = useQuery("processList", () =>
		fetchProcessList({
			env,
			tenant,
			token: auth.userData?.access_token ?? "",
		}),
	);

	const [processIdentifierSelection, setProcessIdentifierSelection] = useSyncedState<number[]>(
		[],
		"process-identifier-selection",
		channel,
	);

	const possibleProcesses =
		clinic.data && processList.data
			? processList.data.data
					.filter(
						(process) =>
							process.clinics_count === 0 ||
							process.clinics.find((processClinic) => processClinic.id === clinic.data.data.id),
					)
					.filter(
						(process) =>
							!processIdentifierSelection.some(
								(selectionId) =>
									!process.process_identifiers.find(
										(processIdentifier) => processIdentifier.id === selectionId,
									),
							),
					)
			: undefined;

	const possibleProcessIdentifiers = possibleProcesses
		? possibleProcesses.map((process) => process.process_identifiers).flat()
		: [];

	const processIdentifierGroups = processIdentifiers
		.map((processIdentifier) => processIdentifier.data?.data)
		.filter((processIdentifier) =>
			processIdentifier?.identifiers.find((identifier) =>
				possibleProcessIdentifiers.find((pi) => pi.id === identifier.id),
			),
		) as ProcessIdentifierGroup[];

	useEffect(() => {
		if (possibleProcesses?.length === 1 && user === "employee") {
			const processId = possibleProcesses[0].id;

			processTrigger("client-process-start", {
				process: processId,
			});
			navigate(`/edit/employee/${key}/${processId}`);
		}
	}, [key, navigate, otherID, possibleProcesses, processTrigger, user]);

	const currentIdentifierGroupIndex = processIdentifierGroups.findIndex(
		(processIdentifier) => !processIdentifierSelection[processIdentifier.id],
	);

	const currentProcessIdentifierGroup =
		currentIdentifierGroupIndex !== undefined
			? processIdentifierGroups[currentIdentifierGroupIndex]
			: undefined;

	return (
		<div>
			<Header />
			<Container>
				{count === 2 ? (
					<>
						<div>{texts.loginProcess[language](key ?? "")}</div>
						<div className="mt-2">
							{texts.selectProcessIdentifier[language](
								currentProcessIdentifierGroup ? currentProcessIdentifierGroup.name : "",
							)}
						</div>
						<Row className="mt-3">
							{currentProcessIdentifierGroup?.identifiers.map((identifier) => (
								<Col key={identifier.id} xs={12} md={6}>
									<ActionCard
										name={identifier.name}
										disabled={user !== "employee"}
										icon={faHospital}
										onClick={() => {
											if (!otherID) return;
											if (user !== "employee") return;
											if (!currentProcessIdentifierGroup) return;

											setProcessIdentifierSelection([...processIdentifierSelection, identifier.id]);
										}}
									/>
								</Col>
							))}
						</Row>
					</>
				) : (
					<Alert variant="success">
						<Spinner size="sm" animation="border" variant="success" />
						<span> {texts.connectingMessage[language]} </span>
					</Alert>
				)}
			</Container>
		</div>
	);
}
