import { Channel, PresenceChannel } from "pusher-js";
import { useClientTrigger } from "@harelpls/use-pusher";

export interface ChunkData {
	id: string;
	index: number;
	chunk: string;
	final: boolean;
}

export function useChunkedClientTrigger<TData = Record<string, unknown>>(
	channel: Channel | PresenceChannel | undefined,
	chunked: boolean,
) {
	const trigger = useClientTrigger<ChunkData | TData>(channel);

	function triggerChunked(eventName: string, data: TData) {
		const chunkSize = 8000; // Pusher limit of 10KB
		const str = JSON.stringify(data);
		const msgId = Math.random() + "";
		for (let i = 0; i * chunkSize < str.length; i++) {
			trigger(eventName, {
				id: msgId,
				index: i,
				chunk: str.substr(i * chunkSize, chunkSize),
				final: chunkSize * (i + 1) >= str.length,
			});
		}
	}

	return chunked ? triggerChunked : trigger;
}
