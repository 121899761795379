import { OpenPadResult } from "../types/signotecPadLibraryTypes";
import { useEffect, useState } from "react";
import { useScript } from "usehooks-ts";

export default function useSignotecPad() {
	const signotecPadLibraryStatus = useScript("/scripts/STPadServerLib.js");

	const [connectionOpened, setConnectionOpened] = useState(false);
	const [openedPad, setOpenedPad] = useState<OpenPadResult>();

	useEffect(() => {
		if (signotecPadLibraryStatus !== "ready") return;

		window.STPadServerLibCommons.createConnection(
			"wss://local.signotecwebsocket.de:49494",
			() => {
				console.log("Signotec Pad Connection Opened");
				setConnectionOpened(true);
			},
			() => {
				console.log("Signotec Pad Connection Closed");
				setConnectionOpened(false);
			},
			(error) => {
				console.error("An error occured on the signotec pad server:", error);
			},
		);

		return () => {
			window.STPadServerLibCommons.destroyConnection();
		};
	}, [signotecPadLibraryStatus]);

	useEffect(() => {
		if (!connectionOpened) {
			setOpenedPad(undefined);
			return;
		}

		const openPad = async () => {
			const searchForPadsParams = new window.STPadServerLibDefault.Params.searchForPads();

			const searchForPadsResult = await window.STPadServerLibDefault.searchForPads(
				searchForPadsParams,
			);

			if (searchForPadsResult.foundPads.length === 0) return;

			const openPadParams = new window.STPadServerLibDefault.Params.openPad(
				searchForPadsResult.foundPads[0].index,
			);

			const openPadResult = await window.STPadServerLibDefault.openPad(openPadParams);

			setOpenedPad(openPadResult);
		};

		openPad();
	}, [connectionOpened]);

	return openedPad;
}
