import { BaseArgs } from "./baseArgs";

export interface DocumentArgs extends BaseArgs {
	documentId: number;
}

export interface DocumentData {
	document: Document;
}

export interface Document {
	id: number;
	name: string;
	is_optional: boolean;
	clinic_id: number | null;
	ward_id: number | null;
	process_step_id: number | null;
	pages: Page[];
	fields: Field[];
}

export interface Field {
	id: number;
	document_id: number;
	field_name: string;
	title: string;
	description: string | null;
	type: FieldType;
	conditions?: FieldCondition;
	field_format: FieldFormat | null;
	prefill: string | null;
	person: Person | null;
	positioning: Positioning[];
}

export enum FieldFormat {
	Date = "dd.MM.YYYY",
}

export enum FieldCondition {
	RequiredRemote = 1,
	RequiredDocument = 2,
	RequiredProcess = 3,
	FieldCondition = 4,
	FieldConditionDocument = 5,
	FieldConditionProcess = 6,
}

export enum FieldType {
	FOPDateField = "FOPDateField",
	PDFCheckBox = "PDFCheckBox",
	PDFDeselectRadioGroup = "PDFDeselectRadioGroup",
	PDFRadioGroup = "PDFRadioGroup",
	PDFTextField = "PDFTextField",
	PDFPatientSignature = "PDFPatientSignature",
	PDFClinicSignature = "PDFClinicSignature",
}

export enum Person {
	Both = "B",
	Staff = "S",
	Patient = "P",
}

export interface Positioning {
	x: number;
	y: number;
	page: number;
	width: number;
	height: number;
	value?: string;
}

export interface Page {
	id: number;
	document_id: number;
	page_number: number;
	skippable: boolean;
	width: number;
	height: number;
}

export default async function fetchDocument(args: DocumentArgs) {
	const response = await fetch(
		`https://${args.tenant}.${args.env}.medix.care/api/v1/admission/document/${args.documentId}`,
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${args.token}`,
			},
		},
	);

	return (await response.json()) as DocumentData;
}
