import { BaseArgs } from "./baseArgs";

export interface ProcessIdentifierGroupArgs extends BaseArgs {
	processIdentifierId: number;
}

export interface ProcessIdentifierGroupData {
	data: ProcessIdentifierGroup;
}

export interface ProcessIdentifierGroup {
	id: number;
	name: string;
	order: number;
	identifiers: ProcessIdentifier[];
}

export interface ProcessIdentifier {
	id: number;
	name: string;
	process_identifier_group_id: number;
}

export default async function fetchProcessIdentifierGroup(args: ProcessIdentifierGroupArgs) {
	const response = await fetch(
		`https://${args.tenant}.${args.env}.medix.care/api/v1/admission/identifier-group/${args.processIdentifierId}`,
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${args.token}`,
			},
		},
	);

	return (await response.json()) as ProcessIdentifierGroupData;
}
