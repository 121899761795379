import { AuthProvider, AuthProviderProps, useAuth } from "oidc-react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PusherProvider, PusherProviderProps } from "@harelpls/use-pusher";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { useLocalStorage } from "usehooks-ts";
import { useMemo } from "react";
import App from "./routes/app";
import Default from "./routes/default";
import Edit from "./routes/edit";
import Employee from "./routes/employee";
import LanguageContext from "./contexts/languageContext";
import Patient from "./routes/patient";
import Processes from "./routes/processes";
import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";

// disable dragging images
window.ondragstart = () => {
	return false;
};

function getPusherConfig(key: string): PusherProviderProps {
	return {
		// required config props
		clientKey: "f4f365697b7d616b0b31",
		cluster: "eu",

		// optional if you'd like to trigger events. BYO endpoint.
		// see "Trigger Server" below for more info
		triggerEndpoint: "/pusher/trigger",

		// required for private/presence channels
		// also sends auth headers to trigger endpoint
		authEndpoint: "https://patientadmission-ident.dev.medix.care/pusher/auth",
		auth: {
			headers: {
				Authorization: `Bearer ${key}`,
			},
		},
	};
}

function getDefaultLanguage() {
	const possibleLanguages = ["de", "en", "es", "fr"];
	const userLanguages = navigator.languages.map((s) => s.slice(0, 2).toLowerCase());

	const matchingLanguage = userLanguages.find((language) => possibleLanguages.includes(language));
	return (matchingLanguage ?? "en") as "de" | "en" | "es" | "fr";
}

const oidcConfig: AuthProviderProps = {
	scope: "openid offline_access profile",
	authority: "https://ident.medix-portal.com/realms/demo/",
	clientId: "online-admission",
	redirectUri:
		process.env.NODE_ENV === "development"
			? "http://localhost:3000/"
			: "https://patientadmission.dev.medix.care",
	autoSignIn: true,
	automaticSilentRenew: true,
};

const queryClient = new QueryClient();

function Root() {
	const [language, setLanguage] = useLocalStorage<"de" | "en" | "es" | "fr">(
		"language",
		getDefaultLanguage(),
	);

	const auth = useAuth();

	const languageContext = useMemo(() => {
		return {
			language,
			setLanguage,
		};
	}, [language, setLanguage]);

	return auth.userData ? (
		<PusherProvider {...getPusherConfig(auth.userData.access_token)}>
			<LanguageContext.Provider value={languageContext}>
				<BrowserRouter>
					<Routes>
						<Route path="/" element={<App />} />
						<Route path="patient" element={<Patient />} />
						<Route path="employee" element={<Employee />} />
						<Route path="edit/:user/:key/:process" element={<Edit />} />
						<Route path="processes/:user/:key" element={<Processes />} />
						<Route path="*" element={<Default />} />
					</Routes>
				</BrowserRouter>
			</LanguageContext.Provider>
		</PusherProvider>
	) : (
		<div />
	);
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<AuthProvider {...oidcConfig}>
				<Root />
			</AuthProvider>
			<ReactQueryDevtools initialIsOpen={false} />
		</QueryClientProvider>
	</React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
