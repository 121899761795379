import ProgressBar from "react-bootstrap/ProgressBar";
import classNames from "classnames";
import "../styles/stepProgressBar.css";

export interface StepProgressBarProps {
	currentStep: number;
	steps: Array<string>;
	onStepClicked?: (step: number) => void;
}

export default function StepProgressBar(props: StepProgressBarProps) {
	return (
		<div className="step-progress-bar">
			<ProgressBar
				className="real-progress-bar"
				striped
				variant="success"
				now={(props.currentStep / (props.steps.length - 1)) * 100}
			/>
			<div className="progress-bar-steps">
				{props.steps.map((step, index) => (
					<div
						key={index}
						className={index > props.currentStep ? "progress-bar-step" : "progress-bar-step done"}
						onClick={() => {
							props.onStepClicked?.(index);
						}}>
						{index + 1}
						<div
							className={classNames({
								"progress-bar-step-text": true,
								first: index === 0,
								last: index === props.steps.length - 1,
							})}>
							{step}
						</div>
					</div>
				))}
			</div>
		</div>
	);
}
