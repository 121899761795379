import { Channel, PresenceChannel } from "pusher-js";
import { ChunkData } from "./useChunkedClientTrigger";
import { useEvent } from "@harelpls/use-pusher";
import { useMemo } from "react";

export default function useChunkedEvent<D>(
	channel: Channel | PresenceChannel | undefined,
	eventName: string,
	callback: (data?: D | undefined, metadata?: { user_id: string } | undefined) => void,
	chunked: boolean,
) {
	const events = useMemo<Record<string, { chunks: Array<string>; receivedFinal: boolean }>>(() => {
		return {};
	}, []);

	function chunkedCallback(data?: ChunkData) {
		if (!data) return;

		if (!(data.id in events)) {
			events[data.id] = { chunks: [], receivedFinal: false };
		}

		const event = events[data.id];
		event.chunks[data.index] = data.chunk;

		if (data.final) event.receivedFinal = true;

		if (event.receivedFinal && event.chunks.length === Object.keys(event.chunks).length) {
			callback(JSON.parse(event.chunks.join("")));
			delete events[data.id];
		}
	}

	useEvent<D & ChunkData>(channel, eventName, chunked ? chunkedCallback : callback);
}
