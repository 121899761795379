/** This class represents a 2-dimensional vector. */
export class Vector2 {
	/** The x component of the vector. */
	public readonly x: number;
	/** The y component of the vector. */
	public readonly y: number;

	constructor(x = 0, y = 0) {
		this.x = x;
		this.y = y;
	}

	public clone() {
		return new Vector2(this.x, this.y);
	}

	public add(vector: Vector2) {
		return new Vector2(this.x + vector.x, this.y + vector.y);
	}

	public subtract(vector: Vector2) {
		return new Vector2(this.x - vector.x, this.y - vector.y);
	}

	public scale(scalar: number) {
		return new Vector2(this.x * scalar, this.y * scalar);
	}

	public scaleX(scalar: number) {
		return new Vector2(this.x * scalar, this.y);
	}

	public scaleY(scalar: number) {
		return new Vector2(this.x, this.y * scalar);
	}

	public dot(vector: Vector2) {
		return this.x * vector.x + this.y + vector.y;
	}

	/** Linearly interpolates between this vector and the provided vector by t. */
	public lerp(vector: Vector2, t: number) {
		t = Math.max(Math.min(t, 1), 0);
		const diff = vector.subtract(this);
		return this.add(diff.scale(t));
	}

	public get magnitude() {
		return Math.sqrt(this.magnitudeSqr);
	}

	private get magnitudeSqr() {
		return this.x * this.x + this.y * this.y;
	}

	public distance(vector: Vector2) {
		return Math.sqrt(this.distanceSqr(vector));
	}

	private distanceSqr(vector: Vector2) {
		const deltaX = this.x - vector.x;
		const deltaY = this.y - vector.y;
		return deltaX * deltaX + deltaY * deltaY;
	}

	public normalize() {
		const magnitude = this.magnitude;
		if (Math.abs(magnitude) < 1e-9) {
			return new Vector2();
		} else {
			return new Vector2(this.x / magnitude, this.y / magnitude);
		}
	}

	public get angle() {
		return Math.atan2(this.y, this.x);
	}

	public rotate(alpha: number) {
		const cos = Math.cos(alpha);
		const sin = Math.sin(alpha);
		return new Vector2(this.x * cos - this.y * sin, this.x * sin + this.y * cos);
	}

	public toString() {
		return "[" + this.x + "; " + this.y + "]";
	}
}
