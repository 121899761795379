import { useContext } from "react";
import { useEvent, usePresenceChannel } from "@harelpls/use-pusher";
import { useNavigate } from "react-router";
import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";
import Header from "../components/header";
import LanguageContext from "../contexts/languageContext";
import Spinner from "react-bootstrap/Spinner";
import TextsContext from "../contexts/textsContext";

export default function Patient() {
	const navigate = useNavigate();

	const texts = useContext(TextsContext);
	const { language } = useContext(LanguageContext);

	const waitingRoom = usePresenceChannel("presence-waiting-room");

	useEvent<{ key: string }>(waitingRoom.channel, "client-start", (data) => {
		if (!data || data.key !== waitingRoom.me?.id) return;
		navigate(`/processes/patient/${data.key}`);
	});

	return (
		<div>
			<Header />
			<Container>
				<>
					<span> {texts.loggedInAs[language](waitingRoom.me?.info.name ?? "")} </span>
					<Alert className="w-100 mt-2" variant="success">
						<Spinner size="sm" animation="border" variant="success" />
						<span> {texts.waitingForEmployeeMessage[language]} </span>
					</Alert>
				</>
			</Container>
		</div>
	);
}
